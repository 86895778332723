import React, { FC } from 'react'
import Helmet from 'react-helmet'
import { useStaticQuery, graphql } from 'gatsby'
import { Mdx, SiteMetadata } from 'common/types'

type Props = {
  description?: string
  keywords?: ReadonlyArray<string>
  title: string
  post?: Mdx
  thumbnailPath?: string
}

type QueryResult = {
  site: {
    siteMetadata: SiteMetadata
  }
  avatar: {
    publicURL: string
  }
}

type StructuredData = {
  [prop: string]: string | undefined | StructuredData
}

const SEO: FC<Props> = ({
  description,
  keywords,
  title,
  post,
  thumbnailPath,
}) => {
  const query = graphql`
    query {
      site {
        siteMetadata {
          title
          description
          author
          siteUrl
          social {
            twitter
          }
        }
      }
      avatar: file(absolutePath: { regex: "/assets/profile-pic.jpg/" }) {
        publicURL
      }
    }
  `
  const result: QueryResult = useStaticQuery(query)

  const siteMetadata = result.site.siteMetadata
  const metaDescription = description || siteMetadata.description
  const siteUrl = siteMetadata.siteUrl
  const thumbnailUrl = thumbnailPath ? siteUrl + thumbnailPath : undefined
  const postUrl = post ? siteUrl + post.fields.slug : undefined

  const structuredData: StructuredData[] = [
    {
      '@context': 'http://schema.org',
      '@type': 'WebSite',
      url: siteUrl,
      name: siteMetadata.title,
      alternateName: siteMetadata.title,
    },
  ]

  if (post) {
    structuredData.push({
      '@context': 'http://schema.org',
      '@type': 'BlogPosting',
      url: postUrl,
      name: title,
      alternateName: siteMetadata.title,
      headline: title,
      image: {
        '@type': 'ImageObject',
        url: thumbnailUrl,
      },
      description,
      author: {
        '@type': 'Person',
        name: siteMetadata.author,
      },
      publisher: {
        '@type': 'Organization',
        url: siteMetadata.siteUrl,
        logo: {
          '@type': 'ImageObject',
          url: siteUrl + result.avatar.publicURL,
        },
        name: siteMetadata.author,
      },
      datePublished: post.frontmatter.date,
      dateModified: post.frontmatter.date,
      mainEntityOfPage: {
        '@type': 'WebSite',
        '@id': siteUrl,
      },
    })
  }

  return (
    <Helmet title={title} titleTemplate={`%s | ${siteMetadata.title}`}>
      <link rel="preconnect" href="https://www.google-analytics.com" />

      <script type="application/ld+json">
        {JSON.stringify(structuredData)}
      </script>

      <meta property="description" content={metaDescription} />
      <meta property="image" content={thumbnailUrl} />
      <meta property="keywords" content={(keywords || []).join(', ')} />

      <meta property="og:title" content={title} />
      <meta property="og:description" content={metaDescription} />
      <meta property="og:type" content="website" />
      <meta property="og:url" content={postUrl || siteUrl} />
      {post && <meta property="og:type" content="article" />}
      <meta property="og:image" content={thumbnailUrl} />

      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:creator" content={siteMetadata.social.twitter} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={metaDescription} />
      <meta name="twitter:image" content={thumbnailUrl} />
    </Helmet>
  )
}

SEO.defaultProps = {
  keywords: [],
  description: '',
}

export default SEO
